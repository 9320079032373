<template>
  <v-app>
    <home-system-bar v-if="true" />

    <home-app-bar />

    <home-view />

    <home-footer />

    <home-calculator v-if="!authorized" />
  </v-app>
</template>

<script>
  import accountMixin from '@/mixins/accounts.js'
  export default {
    name: 'HomeLayout',
    components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeFooter: () => import('@/layouts/home/Footer'),
      HomeCalculator: () => import('@/layouts/home/Calculator'),
      HomeSystemBar: () => import('@/layouts/home/SystemBar'),
      HomeView: () => import('@/layouts/home/View'),
    },
    mixins: [accountMixin],
  }
</script>
